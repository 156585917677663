import React from "react";
import { graphql } from "gatsby";
import Wrapper from "../components/wrapper";
import Flex from "../components/flex";
import Box from "../components/box";
import PageH1 from "../components/pageh1";
import Layout from "../components/layout";
import styled from "styled-components";

import ReactMarkdown from "react-markdown";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { Helmet } from "react-helmet";

const StyledBox = styled(Box)`
  p {
    max-width: 35ch;
  }
`;

const ContactPage = ({ data }) => {
  const { t } = useTranslation();
  return (
    <Layout theme="dark" bg="#fafaf7">
      <Helmet>
        <title>{t("title")} - La Maison Mère</title>
      </Helmet>
      <Flex alignItems="center">
        <Wrapper>
          <Box my={5}>
            <PageH1>{t("title")}</PageH1>
          </Box>
          <Flex justifyContent="center" flexWrap="wrap">
            <StyledBox width={[1, 1 / 2]}>
              <ReactMarkdown>{t("content")}</ReactMarkdown>
            </StyledBox>
            <StyledBox width={[1, 1 / 2]}>
              <ReactMarkdown>{t("content2")}</ReactMarkdown>
            </StyledBox>
          </Flex>
        </Wrapper>
      </Flex>
    </Layout>
  );
};

export default ContactPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["contact"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
