import React from "react";
import styled from "styled-components";
import Box from "./box";

const StyledH1 = styled(Box)`
  font-size: 5vw;
  margin-bottom: 0;

  @media all and (max-width: 768px) {
    font-size: 48px;
  }
`;

const PageH1 = ({ props, children }) => (
  <StyledH1 as="h1" {...props}>
    {children}
  </StyledH1>
);

export default PageH1;
